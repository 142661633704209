@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-decoration: none;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.5);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #555;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #333;
}

body {
  font-family: "Poppins", sans-serif;
  line-height: 1.25rem;
  font-size: 0.875rem;
  letter-spacing: 0.0178571429em;
  font-weight: 100;
}

.header {
  width: 100%;
  height: auto;
  padding: 2rem 1rem;
  position: fixed;
  z-index: 8;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-content: space-around;
  /* background-color: rgba(0, 0, 0, 0.5);
  border-bottom: solid 1px rgba(237, 37, 37, 0.5); */
  transition-duration: 0.5s;
}
.header > a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header > a > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: black;
  padding-right: 1rem;
  border: solid 2px #fff;
}
.header > a > div > h1 {
  font-size: 20px;
  margin-left: 1rem;
  color: #fff;
  transition-duration: 0.5s;
}
.header > a > div > img {
  max-width: 42px;
  max-height: 42px;
  transition-duration: 0.5s;
  padding: 0.3rem 0.2rem;
  background-color: #fff;
}

.header > nav {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-column-gap: 1rem;
  opacity: 1 !important;
}
.header > nav > span {
  color: rgba(255, 255, 255, 0.85);
  font-size: 14px;
  font-weight: normal;
  text-transform: uppercase;
  transition-duration: 0.5s;
  font-weight: 600;
  cursor: pointer;
}
.header > nav > span:hover {
  color: #ab1818;
}

.header-sticky {
  background-color: rgba(0, 0, 0, 0.9);
  padding: 0.5rem;
}
.header-sticky > a > div > h1 {
  font-size: 16px;
  margin-left: 1rem;
  color: #fff;
}
.header-sticky > a > div > img {
  max-width: 34px;
  max-height: 34px;
}

.header > svg {
  fill: #e3e3e3;
  cursor: pointer;
  display: none;
}

@media screen and (max-width: 992px) {
  .header {
    justify-content: space-between;
  }
  .header > svg {
    display: block;
  }
  .header > nav {
    height: 100%;
    position: fixed;
    z-index: -1;
    padding: 0rem 2rem;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    width: 100%;
    opacity: auto;
  }
  .header > nav > span {
    margin-bottom: 1rem;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 0.8rem;
    box-shadow: 0 0 5px 0 #ffffff;
  }
}

.whatsapp {
  position: fixed;
  z-index: 999;
  bottom: 1.5rem;
  right: 1.5rem;
  border-radius: 100%;
  max-width: 45px;
  max-height: 45px;
  transition-duration: 0.5s;
}
.whatsapp > a > img {
  width: 100%;
  height: 100%;
}

.whatsapp-remove {
  transition-duration: 0.5s;

  opacity: 0;
}
.whatsapp-remove > a {
  position: absolute;
  right: -99999;
}

.main {
  width: 100%;
  height: auto;
}

.footer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 13px;
}
.footer > iframe {
  width: 100%;
  height: 450px;
  border: 0;
}
.footer > div {
  width: 100%;
  height: auto;
  padding: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1c1919;
}
.footer > div > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: solid 2px #efefef;
  padding: 8px 1rem;
  color: #fff;
}
.footer > div > div > b {
  background-color: #fff;
  color: #000;
  margin: 0px 0.5rem;
  padding: 1px 5px;
}
.footer > div > img {
  max-width: 38px;
  max-height: 38px;
  margin-left: 1rem;
}
.footer-brands {
  flex: 1;
  color: #fff;
  padding: 0 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  width: 100%;
  left: 0;
}

.footer-brands > img {
  width: auto;
  height: 25px;
  margin-right: 1rem;
}
.footer-brands > img:last-child {
  margin-right: 0;
}

@media screen and (max-width: 992px) {
  .footer {
  }

  .footer > div {
    display: grid;
    grid-template-areas:
      "header header header header header header"
      "logo logo logo logo logo logo";
    row-gap: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .footer-brands {
    position: relative;
    grid-area: header;
    max-width: 100%;
    flex-wrap: wrap;
    row-gap: 1rem;
  }
  .footer > div > div {
    grid-area: logo;
    padding: 8px;
    justify-self: center;
    justify-content: center;
  }

  .footer > div > img {
    display: none;
  }
}

.homeBanner {
  width: 100%;
  height: 95vh;
  background-attachment: fixed;
  background-size: cover;
}

.homeBanner > .homeBanner-content {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.homeBanner > .homeBanner-content > .homeBanner-content-title {
  text-align: center;
  color: #e3e3e3;
  font-size: 60px;
  font-weight: 900;
  text-transform: none;
  letter-spacing: -3px;
  line-height: 1.15;
  max-width: 100%;
  margin-bottom: 2rem;
  font-weight: 700;
}
.homeBanner > .homeBanner-content > span {
  color: #e3e3e3 !important;
  margin: 2rem 0;
  margin-bottom: 1.5rem;

  font-weight: 100;
}
.homeBanner > .homeBanner-content .home-banner-h2 {
  text-align: center;
  color: #e3e3e3;
  font-size: 54px;
  font-weight: 300;
  text-transform: none;
  letter-spacing: -3px;
  font-weight: 300;
}
.homeBanner > .homeBanner-content > .homeBanner-content-parag {
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.2px;
  max-width: 100%;
  margin-top: 2rem;
  text-align: center;
  white-space: pre-line;
  line-height: 28px;
}
.homeBanner-content-button {
  font-size: 16px !important;
  font-weight: 400 !important;
  text-transform: none !important;
  line-height: 1em !important;
  letter-spacing: 0px !important;
  margin-top: 5rem !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  box-shadow: 0 0 5px 0 #ffffff !important;
  padding: 1rem 2.5rem !important;
  border-radius: 50px !important;
  color: #e3e3e3 !important;
  cursor: pointer !important;
  transition-duration: 0.3s !important;
}
.homeBanner-content-button:hover {
  box-shadow: 0 0 10px 0 #ffffff !important;
}

@media screen and (max-width: 992px) {
  .homeBanner {
    background-size: auto;
    background-position-y: -10rem;
    height: auto;
  }

  .homeBanner > .homeBanner-content {
    padding-top: 7rem;
    padding-bottom: 5rem;
  }
  .homeBanner > .homeBanner-content > span:nth-child(2) {
    line-height: 50px;
    width: 100%;
    text-align: center;
    height: 60px;
    transition-duration: 0.5s;
    margin-bottom: 3.5rem;
  }
  .homeBanner > .homeBanner-content > .homeBanner-content-title {
    font-size: 48px;
  }

  .homeBanner > .homeBanner-content .home-banner-h2 {
    font-size: 42px;
  }
  .homeBanner > .homeBanner-content > .homeBanner-content-parag {
    font-size: 18px;
    line-height: -30px;
  }
  .homeBanner-content-button {
    margin-top: 3rem !important;
    line-height: normal !important;
    padding: 1rem 2.5rem !important;
  }
  .homeBanner-content-button:hover {
  }
}

.detailBanner-container {
  width: 100%;
  height: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 5rem 0px;
  white-space: pre-line;
}
.detailBanner-container > h2 {
  font-size: 54px;
  font-weight: normal;
  text-transform: none;
  line-height: 1.1em;
  letter-spacing: -3px;
  color: #2c2c2c;
  margin-bottom: 2rem;
}

.detailBanner {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 400px);
  column-gap: 30px;
  row-gap: 50px;
  justify-content: center;
  /* background-color: #090909; */
  background-color: #ffffff;
  margin-top: 3rem;
}
.detailBanner > section {
  padding: 1rem;
  border-radius: 3px;
}
.detailBanner > p {
  color: #7a7a7a;
}
.detailBanner > section > img {
  width: 50px;
  height: 50px;
  text-align: center;
  margin-bottom: 0.5rem;
}
.detailBanner > section > h3 {
  /* color: #bbbbbb; */
  color: #383737;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  padding-top: 2px;
  margin-bottom: 10px;
  margin-top: 0px;
  text-transform: uppercase;
  text-align: center;
}

.detailBanner > section > p {
  /* color: #666666; */
  color: #4f4c4d;
  font-size: 13px;
  line-height: 20px;
  text-align: justify;
  font-weight: 300;
}
.detailBanner > section > p > span {
  font-weight: 600;
}

@media screen and (max-width: 1200px) {
  .detailBanner {
    grid-template-columns: repeat(3, 1fr);
    padding: 0rem 2rem;
  }
  .detailBanner > section {
  }
}
@media screen and (max-width: 992px) {
  .detailBanner {
    display: flex;
    flex-direction: column;
    margin-top: 0rem;
  }
  .detailBanner > section {
    width: 100%;
  }
  .detailBanner-container > h2 {
    font-size: 42px;
    line-height: 50px;
    word-spacing: 15px;
  }
}

.vehicleBrandsBanner {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
}
.vehicleBrandsBanner-header {
  display: flex;
  justify-content: center;
  margin-top: -2.1rem;
}
.vehicleBrandsBanner-header > span {
  cursor: pointer;
  width: 100%;
  height: 80px;
  text-align: center;
}
.vehicleBrandsBanner-header > span > img {
  max-width: 100%;
  max-height: 100%;
}

.vehicleBrandsBanner-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 70px 20px;
}
.vehicleBrandsBanner-content > div {
  /* max-width: 280px;
  max-height: 100px; */
  margin: 0px 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
}
.vehicleBrandsBanner-content > div > img {
  max-width: 100%;
  height: 100%;
}
@media screen and (max-width: 992px) {
  .vehicleBrandsBanner-content {
    width: 100%;
    background-color: #f9f9f9;
    padding: auto;
    padding: 50px 10px;
  }
  .vehicleBrandsBanner-content > div {
    width: 50%;
    margin: 0px;
    height: 60px;
    margin-bottom: 3rem;
  }

  .vehicleBrandsBanner-content > div:nth-child(5) {
    margin-bottom: 0;
  }
  .vehicleBrandsBanner-content > div:nth-child(6) {
    margin-bottom: 0;
  }
}

.featureOptionsBanner {
  width: 100%;
  padding: 6rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.featureOptionsBanner > h3 {
  font-size: 60px;
  font-weight: 600;
  text-transform: none;
  line-height: 1.1em;
  letter-spacing: -4px;
  color: #2c2c2c;
  margin-bottom: 40px;
}
.featureOptionsBanner > p {
  color: #383737;
  font-size: 24px;
  font-weight: 400;
  text-transform: none;
  margin-bottom: 70px;
}
.featureOptionsBanner > .featureOptionsBanner-wrapper {
  display: flex;
  flex-direction: row;
  width: 1080px;
  max-width: 100%;
}

.featureOptionsBanner
  > .featureOptionsBanner-wrapper
  > .featureOptionsBanner-wrap {
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.featureOptionsBanner
  > .featureOptionsBanner-wrapper
  > .featureOptionsBanner-wrap-left {
  text-align: right;
}
.featureOptionsBanner
  > .featureOptionsBanner-wrapper
  > .featureOptionsBanner-wrap
  > img {
  max-width: 300px;
  max-height: 600px;
}
.featureOptionsBanner
  > .featureOptionsBanner-wrapper
  > .featureOptionsBanner-wrap
  > label {
  display: flex;
  flex-direction: row-reverse;
}
.featureOptionsBanner
  > .featureOptionsBanner-wrapper
  > .featureOptionsBanner-wrap-left
  > label {
  display: flex;
  flex-direction: row-reverse;
}
.featureOptionsBanner
  > .featureOptionsBanner-wrapper
  > .featureOptionsBanner-wrap
  > label
  > img {
  max-width: 20px;
  max-height: 20px;
}

.featureOptionsBanner
  > .featureOptionsBanner-wrapper
  > .featureOptionsBanner-wrap
  > label
  > div {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  text-align: left;
}

.featureOptionsBanner
  > .featureOptionsBanner-wrapper
  > .featureOptionsBanner-wrap
  > label
  > div
  > span {
  width: 100%;
  display: block;
  margin-bottom: 12px;
  font-size: 15px;
  letter-spacing: 1px;
  color: #2c2c2c;
  font-weight: 600;
}
.featureOptionsBanner
  > .featureOptionsBanner-wrapper
  > .featureOptionsBanner-wrap
  > label
  > div
  > p {
  width: 100%;
  color: #4f4c4d;
  display: block;
  margin-bottom: 2rem;
  font-size: 13px;
  height: 80px;
  font-weight: 300;
  text-align: justify;
}

@media screen and (max-width: 992px) {
  .featureOptionsBanner {
    padding: 80px 2rem;
  }
  .featureOptionsBanner > h3 {
    font-size: 42px;
    letter-spacing: -1px;
    text-align: center;
    line-height: 50px;
    word-spacing: 15px;
  }
  .featureOptionsBanner > p {
    margin-bottom: 30px;
    font-size: 21px;
    text-align: center;
    line-height: 30px;
  }
  .featureOptionsBanner > .featureOptionsBanner-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .featureOptionsBanner
    > .featureOptionsBanner-wrapper
    > .featureOptionsBanner-wrap {
    padding: 20px;
    text-align: center;
  }
  .featureOptionsBanner
    > .featureOptionsBanner-wrapper
    > .featureOptionsBanner-wrap-left {
    text-align: center;
  }
  .featureOptionsBanner
    > .featureOptionsBanner-wrapper
    > .featureOptionsBanner-wrap {
    margin-bottom: -40px;
  }
  .featureOptionsBanner
    > .featureOptionsBanner-wrapper
    > .featureOptionsBanner-wrap:nth-last-child(2) {
    display: none;
  }
  .featureOptionsBanner
    > .featureOptionsBanner-wrapper
    > .featureOptionsBanner-wrap
    > label
    > div
    > span {
    width: 100%;
    display: block;
    margin-bottom: 12px;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 600;
  }
  .featureOptionsBanner
    > .featureOptionsBanner-wrapper
    > .featureOptionsBanner-wrap
    > label
    > div
    > p {
    width: 100%;
    color: #999999;
    display: block;
    margin-bottom: 2rem;
    height: auto;
  }
}

.loading-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading-container > img {
  max-width: 50px;
  max-height: 50px;
  border-radius: 50px;
}

.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.9);
  display: none;
}
.modal-active {
  display: block;
}
.modal-container {
  width: 700px;
  max-width: 100%;
  margin: 50px auto;
  position: relative;
}
@media screen and (max-width: 992px) {
  .modal-container {
    width: 100%;
    height: 100%;
    margin: 0;
    overflow: auto;
  }
}
.modal-close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
}
.modal-close > svg {
  fill: #fff;
}

.SupportedVehiclesContainer {
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
}
.SupportedVehicles {
  width: 100%;
  height: auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffff;
  /* padding-top: 161px; */
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: rgba(0, 0, 0, 0.7);
}

.SupportedVehicles > h2 {
  margin-bottom: 40px;
  font-size: 60px;
  letter-spacing: -3.84px;
  line-height: 54px;
  font-weight: 600;
}
.SupportedVehicles > h2 > span {
  font-weight: 600;
}
.SupportedVehicles > h3 {
  text-align: center;
  margin-bottom: 3rem;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 30px;
}

.SupportedVehicles > .SupportedVehicles-carSelectContainer {
  width: 900px;
  height: auto;
  max-width: 100%;
  display: grid;
  grid-template-columns: 150px 270px 270px 150px;
  grid-auto-rows: minmax(auto, 225px);
  column-gap: 30px;
  font-size: 14px;
  font-weight: 500;
}
.SupportedVehicles
  > .SupportedVehicles-carSelectContainer
  > .carSelectContainer-cars {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.SupportedVehicles-carSelectContainer > .carSelectContainer-cars > label {
  width: 100%;
  margin-bottom: 5px;
  padding: 5px;
  margin-left: auto;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 2px 0 #ffffff;
  cursor: pointer;
  border-radius: 50px;
}
.SupportedVehicles-carSelectContainer
  > .carSelectContainer-cars
  > label:last-child {
  margin-bottom: 0px;
}
.SupportedVehicles-carSelectContainer
  > .carSelectContainer-cars
  > .carSelectContainer-cars-active {
  background-color: #ab1818;
}
.SupportedVehicles-carSelectContainer > .carSelectContainer-cars > label:hover {
  background-color: #ab1818;
}
.SupportedVehicles-carSelectContainer > .carSelectContainer-car-model {
  display: flex;
  flex-direction: column;
  background-color: #343232;
  color: #fff;
  padding-top: 5px;
  border-radius: 5px;
  font-weight: 300;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 2px 0 #ffffff;
  overflow: auto;
}
.SupportedVehicles-carSelectContainer
  > .carSelectContainer-car-model
  > section
  > section {
  overflow: auto;
  display: flex;
  flex-direction: column;
}
/* width */
.SupportedVehicles-carSelectContainer
  > .carSelectContainer-car-model
  > section
  > section::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.SupportedVehicles-carSelectContainer
  > .carSelectContainer-car-model
  > section
  > section::-webkit-scrollbar-track {
  background: #343232;
}

/* Handle */
.SupportedVehicles-carSelectContainer
  > .carSelectContainer-car-model
  > section
  > section::-webkit-scrollbar-thumb {
  background: #ab1818;
}

/* Handle on hover */
.SupportedVehicles-carSelectContainer
  > .carSelectContainer-car-model
  > section
  > section::-webkit-scrollbar-thumb:hover {
  background: #333;
}
.SupportedVehicles-carSelectContainer
  > .carSelectContainer-car-model
  > section
  > section
  > label {
  width: 100%;
  cursor: pointer;
  padding-left: 7px;
}
.SupportedVehicles-carSelectContainer
  > .carSelectContainer-car-model
  > section
  > section
  > label:hover {
  background-color: #4f4c4d !important;
}
.SupportedVehicles-carSelectContainer
  > .carSelectContainer-car-model
  > section
  > section
  > div {
  padding-left: 20px;
  cursor: pointer;
}
.SupportedVehicles-carSelectContainer
  > .carSelectContainer-car-model
  > section
  > section
  > div:hover {
  background-color: #4f4c4d;
}

.SupportedVehicles-carSelectContainer
  > .carSelectContainer-car-model
  > .input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  cursor: auto;
  position: relative;
}
.SupportedVehicles-carSelectContainer
  > .carSelectContainer-car-model
  > .input-container
  > svg {
  position: absolute;
  width: 13px;
  height: 15px;
  left: 10px;
  top: 5px;
}

.SupportedVehicles-carSelectContainer
  > .carSelectContainer-car-model
  > .input-container
  > input {
  background-color: #4f4c4d;
  margin: 0 5px 5px 5px;
  padding: 3px;
  padding-left: 20px;
  border: 0;
  flex: 1;
  outline: none;
  color: #fff;
  font-family: "Poppins", sans-serif;
}

.SupportedVehicles-carSelectContainer > .carSelectContainer-car-body {
  display: flex;
  flex-direction: column;
}
.SupportedVehicles-carSelectContainer > .carSelectContainer-car-body > span {
  background-color: #343232;
  margin-bottom: 5px;
  flex: 1;
  cursor: pointer;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
  border-radius: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 2px 0 #ffffff;
}
.SupportedVehicles-carSelectContainer
  > .carSelectContainer-car-body
  > span
  > span {
  flex: 1;
  display: flex;
}
.SupportedVehicles-carSelectContainer
  > .carSelectContainer-car-body
  > span
  > span
  > svg {
  width: 100%;
}
.SupportedVehicles-carSelectContainer
  > .carSelectContainer-car-body
  > span
  > p {
  flex: 2;
  width: 100%;
  text-align: center;
}

.SupportedVehicles-carSelectContainer
  > .carSelectContainer-car-body
  > .carSelectContainer-car-body-active {
  background-color: #ab1818;
}
.SupportedVehicles-carSelectContainer
  > .carSelectContainer-car-body
  > span:hover {
  background-color: #ab1818;
}
.SupportedVehicles-carSelectContainer
  > .carSelectContainer-car-body
  > span:last-child {
  margin-bottom: 0;
}

.SupportedVehicles
  > .SupportedVehicles-carSelectContainer
  > .carSelectContainer-types {
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.SupportedVehicles-carSelectContainer > .carSelectContainer-types > label {
  width: 100%;
  margin-bottom: 5px;
  padding: 3px;
  margin-left: auto;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  background-color: #343232;
  cursor: pointer;
  border-radius: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 2px 0 #ffffff;
}
.SupportedVehicles-carSelectContainer
  > .carSelectContainer-types
  > .carSelectContainer-types-active {
  background-color: #ab1818;
}
.SupportedVehicles-carSelectContainer
  > .carSelectContainer-types
  > label:hover {
  background-color: #ab1818;
}

@media screen and (max-width: 992px) {
  .SupportedVehicles {
    width: 100%;
    height: auto;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .SupportedVehicles > h2 {
    width: 100%;
    font-size: 42px;
    letter-spacing: -1.16px;
    line-height: 50px;
    word-spacing: 15px;
    text-align: center;
    padding: 0;
  }
  .SupportedVehicles > h3 {
    width: 100%;
    font-size: 21px;
    letter-spacing: 0;
    padding: 0;
    line-height: 30px;
  }

  .SupportedVehicles > .SupportedVehicles-carSelectContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .SupportedVehicles
    > .SupportedVehicles-carSelectContainer
    > .carSelectContainer-cars {
    margin-bottom: 20px;
  }
  .SupportedVehicles
    > .SupportedVehicles-carSelectContainer
    > .carSelectContainer-cars
    > label {
    margin-bottom: 0.8rem;
  }
  .SupportedVehicles
    > .SupportedVehicles-carSelectContainer
    > .carSelectContainer-cars
    > label:last-child {
    margin-bottom: 0;
  }

  .SupportedVehicles-carSelectContainer > .carSelectContainer-car-model {
    margin-bottom: 20px;
    min-height: 225px;
    max-height: 225px;
  }
  .SupportedVehicles-carSelectContainer > .carSelectContainer-car-body {
    margin-bottom: 20px;
    min-height: 225px;
    max-height: auto;
  }
  .SupportedVehicles-carSelectContainer > .carSelectContainer-car-body > span {
    padding: 1rem !important;
  }
}

.SupportedVehicles-detail {
  width: 100%;
  height: auto;
  background-color: #383737;
  padding: 50px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.SupportedVehicles-detail-container {
  width: 1000px;
  height: auto;
  max-width: 100%;
}
.SupportedVehicles-detail-container > h3 {
  text-align: center;
  color: #fff;
  margin-bottom: 30px;
  font-size: 56px;
  letter-spacing: -2.58px;
  text-transform: uppercase;
  line-height: 56px;
}
@media screen and (max-width: 992px) {
  .SupportedVehicles-detail {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .SupportedVehicles-detail-container > h3 {
    font-size: 28px;
    letter-spacing: -0.78px;
    line-height: 24px;
  }
}
.SupportedVehicles-detail-container-bar {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 0.5rem;
}
.SupportedVehicles-detail-container-bar:first-child {
  margin-top: 0;
}

.SupportedVehicles-detail-container-bar-title {
  width: 100%;
  padding: 10px 15px;
  font-size: 1.2em;
  font-weight: bolder;
  color: #fff;
  background: rgba(32, 32, 32, 0.5);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 1.8;
  font-weight: 300;
}
.SupportedVehicles-detail-container-bar-content {
  width: 100%;
  height: auto;
  display: none;
  transition: max-height 0.2s ease-out;
  background: rgba(32, 32, 32, 0.3);
  color: #fff;
  border-radius: 20px;
}
.SupportedVehicles-detail-container-bar-content > p {
  padding: 10px 15px;
  font-weight: 300;
}
.SupportedVehicles-detail-container-bar-content-active {
  display: block;
}
.SupportedVehicles-detail-container-bar-content-wrapper {
  width: 100%;
  height: auto;
  max-height: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 150px;
  column-gap: 30px;
  row-gap: 20px;
  padding: 10px 15px;
  padding-top: 0;
  margin-bottom: 2rem;
}

.SupportedVehicles-detail-container-bar-content-wrapper-wrap {
  height: auto;
  background-size: cover;
  position: relative;
  color: #fff;
  border-radius: 20px;
  overflow: hidden;
  font-weight: 300;
  cursor: pointer;
}
@media screen and (max-width: 992px) {
  .SupportedVehicles-detail-container-bar-content-wrapper {
    display: flex;
    flex-direction: column;
  }
  .SupportedVehicles-detail-container-bar-content-wrapper-wrap {
    width: 100%;
    height: 150px;
  }
}
.SupportedVehicles-detail-container-bar-content-wrapper-wrap:hover {
  box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.5);
}
.SupportedVehicles-detail-container-bar-content-wrapper-wrap-content {
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1)
  );
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
}

.car-detail-modal {
  width: 100%;
  height: auto;
  color: #fff;
  text-align: justify;
}
.car-detail-modal > img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  margin-bottom: 26px;
  border-radius: 20px;
}

.car-detail-modal-content {
  width: 100%;
  height: auto;
  padding: 0px 50px;
  position: relative;
  min-height: 300px;
}
.car-detail-modal-content > span {
  font-weight: 600;
  margin-top: 2em;
}
.car-detail-modal-content > h3 {
  font-weight: 600;
  font-size: 1.7em;
  margin-top: 0.4em;
}

.car-detail-modal-content > p {
  margin: 16px 0px;
  padding: 16px 0px;
  border-top: solid 2px rgba(55, 55, 55, 0.8);
  border-bottom: solid 2px rgba(55, 55, 55, 0.8);
  white-space: pre-line;
  font-weight: 300;
}

.car-detail-modal-button-container {
  width: auto;
  height: auto;
  color: #fff;
  position: absolute;
  top: 45%;
  bottom: 45%;
  right: 0;
  z-index: 99999;
}
.car-detail-modal-button-container > div {
  height: 20px;
  width: 20px;
  cursor: pointer;
  z-index: 9999 !important;
}

.car-detail-modal-button-container-lef {
  left: 0;
}

@media screen and (max-width: 992px) {
  .car-detail-modal-button-container {
    right: 10px;
  }
  .car-detail-modal-button-container-lef {
    left: 10px;
  }
  .car-detail-modal-button-container-left {
    left: 10px;
  }
  .car-detail-modal {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .car-detail-modal > img {
    width: 100%;
    max-height: 250px;
    object-fit: cover;
    margin-bottom: 26px;
    border-radius: 0px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .car-detail-modal-content {
    flex: 1;
    overflow: auto;
  }
}
